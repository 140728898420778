import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { chargesSorted } from '../../services';
import { getOrder } from '../../services/querys';
import useUpdateFromSocket from 'hooks/useUpdateFromSocket';

const useGetCharges = (letter:string) => {
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  useUpdateFromSocket(['accounting-order', orderId]);
  const { isLoading, isFetching } = useQuery(['accounting-order', orderId],
    () => getOrder(orderId),
    {
      enabled: orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
		  if (data) {
          	setAccounting(data.getOrder.accounting.filter((acc) => acc.number !== null && acc.letter !== null));
		  }
      }

    },);

  const handleSetAccounting = useCallback((accountingData: AccountingType[]) => {
    setAccounting(chargesSorted(accountingData.filter((asc) => asc.letter !== null && asc.number !== null)));
  }, []);

  useEffect(() => {
    if (isFetching && !isLoading) {
		  showAlert('Updating...', 'info');
    }
	  }, [isFetching, showAlert, isLoading]);

  return {
    accounting,
    handleSetAccounting,
    isLoading,
    isFetching
  };
};

export default useGetCharges;
