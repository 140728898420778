import { getOrderStatuses, OrderStatus } from 'graphql/order-status/queries';
import { QueryConfig, useQuery } from 'react-query';
import { capitalize } from 'utils/helpers';

export type Status = {
  text: String
} & OrderStatus;

type Params = {
  queryConfig?: QueryConfig<Status[], Error>
}

export const useGetOrderStatuses = ({ queryConfig }: Params) => useQuery(["order-statuses"], {
  ...queryConfig,
  staleTime: Infinity,
  cacheTime: Infinity,
  queryFn: async () => {
    const response = await getOrderStatuses();
    const statuses: Status[] = response.__type.enumValues.map(status => ({
      ...status,
      text: capitalize(status.name)
    }));
    return statuses;
  }
});
