import { OrderProperty } from 'types/order';

export const extractPropertyData = (property: OrderProperty) => {
  const {
    _id,
    address,
    parcel_ids,
    accounting,
    loans,
    prorations,
    ...rest
  } = property;
  const parcelIds: string[] = parcel_ids
    .map((parcelId) => {
      if (typeof parcelId !== 'string') return parcelId.value;
      return parcelId;
    }).filter((parcelId) => parcelId !== '');

  const propertyData: Partial<OrderProperty> = {
    ...rest,
    parcel_ids: parcelIds,
  };
  return propertyData;
};
