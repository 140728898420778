import { capitalize } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { OrderProperty } from 'types/order';
import useAlert from 'utils/alert';
import { LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';
import useDeleteLedgerAssociation from 'utils/ledger/use-delete-ledger-association';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

type UseTabAmountHooksParams = {
  control: Control<OrderProperty, object>;
};

export const useTabAmountHooks = ({ control, }: UseTabAmountHooksParams) => {
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const showAlert = useAlert();
  const [openPartyLedgerModal, setOpenPartyLedgerModal] = useState<boolean>(false);
  const [entryLedger, setEntryLedger] = useState<AccountingType>();
  const [partyLedgerEntry, setPartyLedgerEntry] = useState<AccountingInvolvedParty>();
  const queryCache = useQueryCache();
  const [purchasePriceEntries, setPurchasePriceEntries] = useState<
    AccountingInvolvedParty[]
  >([]);

  const { fields: accountingEntries } = useFieldArray<Omit<OrderProperty, 'loans' | 'prorations'>>({
    control,
    name: 'accounting',
  });

  useEffect(() => {
    const purchasePriceEntry = accountingEntries.find((entry) => (entry as AccountingType).code.includes(LEDGER_CODE_PURCHASE_PRICE));
    const purchasePriceEntriesList = purchasePriceEntry
      ? (purchasePriceEntry as AccountingType).involved_parties
      : [];
    setEntryLedger(purchasePriceEntry as AccountingType);
    setPurchasePriceEntries(purchasePriceEntriesList);
  }, [accountingEntries, control]);

  const onUpdatePurchasePriceEntries = () => {
    queryCache.invalidateQueries(['order-properties', orderId]);
  };
  const { deleteAssociation, deleteAssociationResponse } = useDeleteLedgerAssociation((response) => {
    if (response) {
	  showAlert(capitalize(t('dialogs:order-updated')), 'success');
	  deleteAssociationResponse.reset();
	  onUpdatePurchasePriceEntries();
    }
  },
  () => {
    showAlert(capitalize(t('dialogs:there-is-an')), 'error');
  },);

  const onRemoveOrderPartyEntry = (entry: AccountingInvolvedParty) => {
    deleteAssociation({
      id: orderId,
      accountingId: entryLedger?._id!,
      kind: entry.kind,
      partyId: entry._id,
    });
  };

  return {
    orderId,
    accountingEntries,
    purchasePriceEntries,
    openPartyLedgerModal,
    setOpenPartyLedgerModal,
    entryLedger,
    partyLedgerEntry,
    setPartyLedgerEntry,
    onUpdatePurchasePriceEntries,
    onRemoveOrderPartyEntry,
    t,
  };
};
