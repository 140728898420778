import { useCallback, useEffect, useState } from 'react';
import { OrderProperty } from 'types/order';
import { LEDGER_CODE_LOAN_AMOUNT } from 'utils/constants';

type Params = {
  properties: OrderProperty[]
};

export const useInformationBar = ({ properties }: Params) => {
  const [showPropertyList, setShowPropertyList] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<{
    address: string,
    loanAmount: number | undefined
  }>({ address: '', loanAmount: undefined });

  const selectProperty = useCallback(({ property }: { property: OrderProperty}) => {
    const { address } = property.address;
    const loanAmountEntry = property?.accounting?.find((entry) => entry.code.includes(LEDGER_CODE_LOAN_AMOUNT));
    const loanAmountValue = loanAmountEntry?.amount;

    setSelectedProperty({
      address,
      loanAmount: loanAmountValue
    });
  }, []);

  useEffect(() => {
    if (properties.length > 0) {
      selectProperty({ property: properties[0] });
    } else if (selectedProperty.address) {
      setSelectedProperty({ address: '', loanAmount: undefined });
    }
  }, [properties]);

  return {
    showPropertyList,
    setShowPropertyList,
    selectedProperty,
    selectProperty
  };
};
