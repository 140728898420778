import { QueryKey, useQueryCache } from "react-query";
import { useAccountingUpdateSocketEvent } from "./useAccountingUpdateFromSocket";

type Params = {
  queries: QueryKey[]
}

export const useSocketAccountingEventInvalidator = ({ queries }: Params) => {
  const queryCache = useQueryCache();

  useAccountingUpdateSocketEvent({
    callback: () => {
      queries.forEach(query => {
        queryCache.invalidateQueries(query);
      });
    }
  });
};