import { useLLMProxyServiceContext } from 'contexts/llmProxySerivceContext';
import { GetLoansReturn } from 'graphql/loans/queries';
import { useDeleteLoan } from 'hooks/useDeleteLoan';
import { useDeleteLoanInterest } from 'hooks/useDeleteLoanInterest';
import { useGetLoans } from 'hooks/useGetLoans';
import { useUpdateEntry } from 'hooks/useUpdateEntry';
import { useUpdateLoan } from 'hooks/useUpdateLoan';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { hasObjectAllPropertiesWithValues } from 'services/inference/utils';
import { filterAccountingEntriesByLoanId } from 'utils/accounting';
import useAlert from 'utils/alert';
import { LEDGER_CODE_LOAN_AMOUNT } from 'utils/constants';
import { AccountingInvolvedParty, AccountingType, Loan } from 'v2-types/order';

import { LoanFormType, ModalData } from './types';
import { getLoanFormDataFromLoan } from './utils';
import { loanInitialValues } from './utils/loanInitialValues';
import { createLoan } from 'graphql/loans/mutations';
import { useGetOrderAccounting } from 'hooks/useGetOrderAccounting';
import { useDeleteOrderLedgerAssociation } from 'hooks/useDeleteOrderLedgerAssociation';
import { useTranslation } from 'react-i18next';
import { useSocketAccountingEventInvalidator } from 'hooks/useSocketAccountingEventInvalidator';

export const useLoansHooks = () => {
  const { id: orderId, loanId } = useParams<{ id: string, loanId?: string }>();
  const history = useHistory();
  const showAlert = useAlert();
  const [selectedLoan, setSelectedLoan] = useState<Loan | undefined>(undefined);
  const [modalData, setModalData] = useState<ModalData>();
  const loanForm = useForm<LoanFormType>({ defaultValues: loanInitialValues, mode: 'onChange' });
  const queryCache = useQueryCache();
  const { llmServiceRef } = useLLMProxyServiceContext();
  const { t } = useTranslation();

  useSocketAccountingEventInvalidator({
    queries: [['party-order', orderId], ['loans', orderId]]
  });

  const onSelectLoan = (loan: Loan | undefined) => {
    if (loan) {
      const loanAmountEntry = loan.accounting.find((entry) => entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT));
      history.push(loan._id);

      loanForm.reset({
        ...loan,
        loanAmountEntry
      });
      setSelectedLoan(loan);
    }
  };

  const {
    data,
    isLoading: isLoansLoading,
    isFetching: isLoansFetching
  } = useGetLoans({
    orderId,
    queryConfig: {
      onSuccess: (response) => {
        if (!loanForm.formState.isDirty) {
          let loan: Loan | undefined = response.getOrder.loans[0];
          if (loanId) {
            loan = response.getOrder.loans.find((loanItem) => loanItem._id === loanId);
          }
          onSelectLoan(loan);
        }
      },
      staleTime: 50 * (60 * 1000)
    }
  });

  const { data: orderAccountingEscrow } = useGetOrderAccounting({
    orderId,
    filters: {
      accounting: "/^(escrow_estimated|escrow_initial|escrow_monthly)$/"
    },
    queryKey: `${orderId}-accountingEscrow`,
    queryConfig: {
      cacheTime: 50 * (60 * 1000),
    }
  });
  const orderAccountingEscrowEntries = orderAccountingEscrow?.getOrder.accounting ?? [];

  const updateLoansCache = (loans: Loan[], loanIdToSelect?: string) => {
    queryCache.setQueryData<GetLoansReturn>(
      ['loans', orderId],
      () => {
        if (loanIdToSelect !== undefined) {
          const loanToSelect = loans.find((loan) => loan._id === loanIdToSelect);
          onSelectLoan(loanToSelect);
        }

        const newData: GetLoansReturn = { getOrder: { loans } };
        return newData;
      },
      { cacheTime: 50 * (60 * 1000) }
    );
  };

  const [deleteLoaninterest, { isLoading: isDeleteInterestLoading }] = useDeleteLoanInterest({
    queryConfig: {
      onSuccess: (response) => {
        updateLoansCache(response.deleteOrderLoanInterest.loans, loanId);
        setModalData({ modal: '' });
        showAlert('Interest successfully deleted', 'success');
      },
      onError: () => showAlert('Unexpected error ocurred while trying to delete the interest', 'error')
    }
  });

  const [deleteLoan, { isLoading: isDeleteLoanLoading }] = useDeleteLoan({
    queryConfig: {
      onSuccess: (response) => {
        const loan: Loan | undefined = response.deleteOrderLoan.loans[0];
        updateLoansCache(response.deleteOrderLoan.loans, loan ? loan._id : undefined);
        setModalData({ modal: '' });
        showAlert('Loan successfully deleted', 'success');
      },
      onError: () => {
        showAlert('Unexpected error ocurred while trying to delete the loan', 'error');
      }
    }
  });

  const [updateLoan, { isLoading: isUpdateLoanLoading }] = useUpdateLoan({
    queryConfig: {
      onSuccess: (response) => {
        updateLoansCache(response.updateOrderLoan.loans, loanId);
        showAlert('Loan successfully updated', 'success');
      },
      onError: () => {
        showAlert('Unexpected error ocurred while trying to update the loan', 'error');
      }
    }
  });

  const [updateEntry, { isLoading: isEntryUpdateLoading }] = useUpdateEntry({});

  useEffect(() => {
    if (!selectedLoan && data?.getOrder.loans) onSelectLoan(data.getOrder.loans[0]);
    llmServiceRef.current.addCurrentTool({
      name: "createNewLoan",
      tool: async ({ tool, toolParams }) => {
        if (!(tool === "createNewLoan" && toolParams && hasObjectAllPropertiesWithValues(toolParams))) return "Please provide all the required infomation.";

        const {
          orderId: id,
          propertyId,
          loanNumber
        } = toolParams;

        const response = await createLoan({
          orderId: id,
          estateId: propertyId,
          loanNumber
        });

        const { loans } = response.createOrderLoan;
        const createdLoan = loans.find((loan) => loan.loan_number === loanNumber);
        history.push(createdLoan?._id || '');
        updateLoansCache(loans, createdLoan?._id || '');

        return "Loan successfully created";
      }
    });
  }, []);

  useEffect(() => {
    if (isLoansLoading || isLoansFetching) {
      showAlert('Getting new information', 'info');
    }
  }, [isLoansLoading, isLoansFetching]);

  const updateAccountingCache = (accounting: AccountingType[]) => {
    queryCache.setQueryData<GetLoansReturn>(
      ['loans', orderId],
      (dataCache) => {
        const loanIndex = dataCache?.getOrder.loans.findIndex((loan) => loan._id === selectedLoan?._id);
        const newData: GetLoansReturn = {
          getOrder: {
            loans: [],
            configuration: {
              additional_fees_letter: dataCache?.getOrder?.configuration?.additional_fees_letter || null
            }
          }
        };
        if (loanIndex !== undefined && loanIndex >= 0) {
          const loans = [...dataCache?.getOrder.loans || []];
          loans[loanIndex].accounting = filterAccountingEntriesByLoanId(accounting, selectedLoan?._id!);
          newData.getOrder.loans = loans;
        }
        return newData;
      },
      { cacheTime: 50 * (60 * 1000) }
    );
  };

  const onClose = () => setModalData({ modal: '' });

  const [
    deleteAdditionalEntry,
    { isLoading: isDeleteAdditionalLoading }
  ] = useDeleteOrderLedgerAssociation({
    queryConfig: {
      onError: () => showAlert('Unexpected error ocurred while trying to delete the additional', 'error')
    }
  });

  const deleteAdditional = () => {
    if (modalData?.modal === "deleteAdditional") {
      const { _id: additionalId, kind } = modalData?.entry!;
      const { _id: accountingId } = loanForm.getValues('loanAmountEntry') as AccountingType;
      deleteAdditionalEntry({
        orderId,
        kind,
        accountingId,
        partyId: additionalId,
        filters: {
          accounting: `/.*${selectedLoan?._id}.*/`
        }
      }, {
        onSuccess: () => {
          const loanAmountEntry = loanForm.getValues('loanAmountEntry');
          if (loanAmountEntry?.involved_parties) {
            loanAmountEntry.involved_parties = loanAmountEntry?.involved_parties.filter(party => party.kind !== kind);
            loanForm.setValue("loanAmountEntry.involved_parties", loanAmountEntry.involved_parties);
          }
          showAlert('Additional successfully deleted', 'success');
          onClose();
        }
      });
    }
  };

  const loans = data?.getOrder.loans || [];

  const onDeleteLoanInterest = () => {
    if (modalData?.modal === 'deleteInterest' && selectedLoan) {
      deleteLoaninterest({
        orderId,
        loanId: selectedLoan._id,
        interestId: modalData?.interestId
      });
    }
  };

  const { formState: { dirtyFields, isDirty }, reset } = loanForm;
  const onSave = loanForm.handleSubmit(async ({
    loanAmountEntry,
    ...rest
  }) => {
    if (isDirty && selectedLoan) {
      if (dirtyFields.loanAmountEntry?.amount) {
        await updateEntry({
          id: orderId,
          accountingId: loanAmountEntry?._id!,
          data: { amount_override: loanAmountEntry?.amount!, }
        });
      }

      const dirtyFieldsMutable = { ...dirtyFields };
      delete dirtyFieldsMutable.loanAmountEntry;
      if (Object.keys(dirtyFieldsMutable).length > 0) {
        const loanData = getLoanFormDataFromLoan(rest as Loan) as Partial<Loan>;
        await updateLoan({
          orderId,
          loanId: selectedLoan._id,
          data: {
            ...loanData
          }
        });
      }
      reset();
    }
  });

  const onDeleteLoan = () => {
    if (loanId) {
      deleteLoan({
        orderId,
        loanId
      });
    }
  };

  const onSuccesEntryEdited = (editedEntry: AccountingType) => {
    if (selectedLoan) {
      const selectedLoanEdited: Loan | undefined = { ...selectedLoan };
      const editedEntryIndex = selectedLoanEdited.accounting.findIndex(entry => entry._id === editedEntry._id);
      selectedLoanEdited.accounting[editedEntryIndex] = editedEntry;
      updateAccountingCache(selectedLoanEdited.accounting as AccountingType[]);
    }
  };

  const onSuccesPartyEntryEdited = (editedEntry: AccountingInvolvedParty) => {
    if (selectedLoan) {
      const selectedLoanEdited: Loan | undefined = { ...selectedLoan };
      const loanAmountEntryIndex = selectedLoanEdited.accounting.findIndex((entry) => entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT));
      const partyIndex = selectedLoanEdited.accounting[loanAmountEntryIndex].involved_parties.findIndex(party => party.kind === editedEntry.kind);

      selectedLoanEdited.accounting[loanAmountEntryIndex].involved_parties[partyIndex] = editedEntry;
      updateAccountingCache(selectedLoanEdited.accounting as AccountingType[]);
    }
  };

  return {
    loans,
    loanId,
    onSelectLoan,
    selectedLoan,
    loanForm,
    setModalData,
    modalData,
    onDeleteLoanInterest,
    isDeleteInterestLoading,
    updateLoansCache,
    onDeleteLoan,
    isDeleteLoanLoading,
    onSave,
    isUpdateLoanLoading: isUpdateLoanLoading || isEntryUpdateLoading,
    isLoansLoading,
    orderAccountingEscrowEntries,
    deleteAdditional,
    isDeleteAdditionalLoading,
    onClose,
    additionalFeesLetter: data?.getOrder?.configuration?.additional_fees_letter || null,
    t,
    onSuccesEntryEdited,
    onSuccesPartyEntryEdited
  };
};
