/* eslint-disable react/no-array-index-key */
import {
  CircularProgress,
  ClickAwayListener,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Column } from 'types/common';
import useStyles from '../style';
import LightTooltip from 'components/LigthTooltip/ligthTooltip';
import { formatMoney } from 'utils/helpers';

type BodyType = {
  columns: Column[];
  data: any;
  disabledBorderCell?: boolean;
  disableFullborderCell?: boolean;
  onClickItem?: (row: any, column: Column, position?: number) => void;
  indexRow: number
};

const RowTable = ({
  columns,
  data,
  indexRow,
  onClickItem,
  disabledBorderCell,
  disableFullborderCell,
}: BodyType) => {
  const {
    paddingCell,
    borderBottomCell,
    borderTopCell,
    borderCellStyle,
  } = useStyles();
  const [open, setOpen] = useState(false);
  const referenceCalculatedValue = useRef(0);

  useEffect(() => {
    if (referenceCalculatedValue.current === 0 && data?.amount_calculated) {
      referenceCalculatedValue.current = data?.amount_calculated;
    }
  }, [data]);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const enableTooltip = referenceCalculatedValue.current > 0 && referenceCalculatedValue.current !== data?.amount;

  return (
    <>
      {columns?.map((column: Column, index: number) => column?.renderer && (index === 4 ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement="top"
            title={(
              <Typography component="span" style={{ color: 'white' }} variant="caption">
                {`The amount calculated is: ${formatMoney(referenceCalculatedValue.current || 0)}`}
              </Typography>
            )}
          >
            <TableCell
              key={`cell-item-${index}`}
              align="left"
              style={{
                height: 0,
                minWidth: column.header?.minWidth,
                width: column.header?.width,
                backgroundColor: enableTooltip ? '#FFFF6B' : 'transparent'
              }}
              onMouseEnter={enableTooltip ? handleTooltipOpen : undefined}
              onMouseLeave={enableTooltip ? handleTooltipClose : undefined}
              className={`
            ${paddingCell}
            ${!disabledBorderCell && borderBottomCell}
            ${!disabledBorderCell && borderTopCell}
            ${!disableFullborderCell && borderCellStyle}
	        `}
              onClick={(e) => {
                e.stopPropagation();
                if (onClickItem) {
                  onClickItem(data, column, indexRow);
                }
              }}
            >
              {column?.renderer({ row: data, column, index, indexRow })}
            </TableCell>
          </LightTooltip>
        </ClickAwayListener>
      ) :
        <TableCell
          key={`cell-item-${index}`}
          align="left"
          style={{
            height: 0,
            minWidth: column.header?.minWidth,
            width: column.header?.width,
            backgroundColor: index === 4 ? '#FFFF6B' : 'transparent'
          }}
          onMouseEnter={index === 4 ? handleTooltipOpen : undefined}
          onMouseLeave={index === 4 ? handleTooltipClose : undefined}
          className={`
    ${paddingCell}
    ${!disabledBorderCell && borderBottomCell}
    ${!disabledBorderCell && borderTopCell}
    ${!disableFullborderCell && borderCellStyle}
  `}
          onClick={(e) => {
            e.stopPropagation();
            if (onClickItem) {
              onClickItem(data, column, indexRow);
            }
          }}
        >
          {column?.renderer({ row: data, column, index, indexRow })}
        </TableCell>
      ))}
    </>
  );
};

RowTable.defaultProps = {
  onClickItem: undefined,
  disabledBorderCell: false,
  disableFullborderCell: false,
};

export default RowTable;
