import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import ChargesContainer from '../components/chargeContainer';
import { ChargesTable } from '../components/ChargesTable';

const ChargesG = () => {
  const { t } = useTranslation();

  return (
    <ChargesContainer chargesLetter="G">
      <Grid container direction="column">
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Paper>
            <Header
              title={t('charges:escrow')}
              t={t}
            />
            <ChargesTable />
          </Paper>
        </Grid>
      </Grid>
    </ChargesContainer>
  );
};

export default ChargesG;
