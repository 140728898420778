import { AutoTemplate } from "types/auto-templates";

export const orderTemplatesAlphabetically = (templates: AutoTemplate[]) => {
  return templates.sort((templateA, templateB) => {
    const templateNameA = templateA.name.toLowerCase();
    const templateNameB = templateB.name.toLowerCase();

    if (templateNameA > templateNameB) return 1;
    if (templateNameA < templateNameB) return -1;
    return 0;
  });
};