import TableCharges from "./tableCharges";
import SkeletonCharges from "./skeleton";
import { useChargesContext } from "contexts/chargesContext";
import React, { FC } from "react";
import { AccountingType } from "v2-types/order";

type Props = {
  isLoadingInfo?: boolean,
  accountingFilter?: (accounting: AccountingType[]) => AccountingType[]
}

export const ChargesTable: FC<Props> = ({
  isLoadingInfo,
  accountingFilter
}) => {
  const {
    isFetching
  } = useChargesContext();
  return (
    <>
      {isFetching ? <SkeletonCharges /> : <TableCharges isLoadingInfo={isLoadingInfo} accountingFilter={accountingFilter} />}
    </>
  );
};