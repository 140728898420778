import { useState } from "react";
import { useParams } from "react-router-dom";
import { infoUpdateType } from "types/notifications";
import { useSubscribeToSocketEvent } from "./useSubscribeToSocketEvent";

type Params = {
  callback: (data: infoUpdateType) => void
}

export const useAccountingUpdateSocketEvent = ({ callback }: Params) => {
  const { id: orderId } = useParams<{ id: string }>();
  const [time, setTime] = useState<string|null>(null);

  useSubscribeToSocketEvent({
    eventKey: 'info-update',
    callback: (data: infoUpdateType) => {
      const actualDate = new Date();
      if (data.name === 'Worker' && data.kind === 'workerAccountingLedger' && data.update?._id === orderId) {
        setTime(actualDate.getTime().toString());
        callback(data);
      }
    }
  });
  return {
    time
  };
};