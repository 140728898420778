/* eslint-disable radix */
import React from 'react';
import { Column } from 'types/common';
import { formatMoney } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import InfoComponent from '../info-component';
import { ColumnsType } from '../prototype-new-table-charges/services';
import { CellIndicator, CellText } from '../table-cells';

export type columnsDataSet = {
  indexRow: number;
  id: string,
  description: string;
  chargeData: AccountingType,
  number: number;
}

type RendererParams = {
  row: columnsDataSet;
  column: Column;
  index: number;
  indexRow?: number;
  shouldCollapse?: boolean
};

export const generatekDues = (
  t,
  theme,
  title,
  handleEditEntry,
  handlePayor,
  handleDelete
): ColumnsType => ({
  paid_seller: {
    header: {
      title,
      textColor: theme.palette.primary.light
    },
    columns: [{
      header: {
        title: '',
        width: '50px',
        minWidth: '50px'
      },
      renderer: ({ row }: RendererParams) => (
        <CellIndicator
          isActive={false}
          position={row.number}
        />
      )
    },
    {
      header: {
        title: t('charges:statement-text'),
        textColor: theme.palette.primary.light
      },
      renderer: ({ row }: RendererParams) => (
        <CellText position="left" data={row.description} />
      )
    },
    {
      header: {
        title: t('lender:amount'),
        textColor: theme.palette.primary.light
      },
      renderer: ({ row }: RendererParams) => (
        <CellText position="right" data={formatMoney(row?.chargeData?.amount || 0)} />
      )
    },
    {
      header: {
        title: '',
        width: '50px',
        minWidth: '50px'
      },
      renderer: ({ row }: RendererParams) => (
        <InfoComponent
          onClickEdit={() => {
            handleEditEntry(row);
          }}
          onClickPayer={() => {
            handlePayor(row);
          }}
          onClickDelete={(row.chargeData.kind.includes('manual') && row.chargeData._id.length > 10) ? () => {
            handleDelete(row);
          } : undefined}
        />
      )
    }]
  }
});

export const generateKDueAdjustments = (
  t,
  theme,
  title,
  handleEditEntry,
  handlePayor,
  handleDelete,
  isAddendum?: boolean
): ColumnsType => ({
  paid_seller: {
    header: {
      title,
      padding: theme.spacing(2.3125)
    },
    columns: [{
      header: {
        title: '',
        disabled: true,
        width: '50px',
        minWidth: '50px'
      },
      renderer: ({ row }: RendererParams) => (
        <CellIndicator
          isActive={false}
          position={row?.number}
          StringValue={isAddendum ? 'AD' : undefined}
        />
      )
    },
    {
      header: {
        title: '',
        disabled: true,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText position="left" data={row.description} />
      )
    },
    {
      header: {
        title: '',
        disabled: true,
        textColor: theme.palette.primary.light
      },
      renderer: ({ row }: RendererParams) => (
        <CellText position="right" data={formatMoney(row?.chargeData?.amount || 0)} />
      )
    },
    {
      header: {
        title: '',
        disabled: true,
        textColor: theme.palette.primary.light,
        width: '50px',
        minWidth: '50px'
      },
      renderer: ({ row }: RendererParams) => {
        return (
          <InfoComponent
            onClickEdit={() => {
              handleEditEntry(row);
            }}
            onClickPayer={() => {
              handlePayor(row);
            }}
            onClickDelete={(row.chargeData.kind.includes('manual') && row.chargeData._id.length > 10) ? () => {
              handleDelete(row);
            } : undefined}
          />
        );
      }
    }
    ]
  },

});
