import { DocumentsType } from "v2-types/order";

export const orderDocumentsAlphabetically = (documents: DocumentsType[]) => {
  return documents.sort((documentA, documentB) => {
    const documentDescriptionA = documentA.description.toLowerCase();
    const documentDescriptionB = documentB.description.toLowerCase();

    if (documentDescriptionA > documentDescriptionB) return 1;
    if (documentDescriptionA < documentDescriptionB) return -1;
    return 0;
  });
};