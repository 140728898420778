import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';

import EstimatedTaxesSectionTable from './components/estimated-taxes-section-table';
import Header from './components/header';
import PaymentSectionTable from './components/payment-section-table';
import { UseFormReturn } from 'react-hook-form';
import { accountingProjectedType, projectedPayments } from './services';
import { useTranslation } from 'react-i18next';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { AccountingType } from 'v2-types/order';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  header: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
}));

type ProjectedPaymentsSceneProps = {
  methods: UseFormReturn<projectedPayments, any, undefined>
  loadingSave?: boolean,
  handleSave: (data: projectedPayments) => void,
  handleEditMortgage: () => void,
  handleEditEstimated: () => void,
  currentEntry: accountingProjectedType | undefined,
  setCurrentEntry: React.Dispatch<React.SetStateAction<accountingProjectedType | undefined>>,
  estimatedCalculatedValue: number,
  mortgageCalculatedValue: number

};

const ProjectedPaymentsScene = ({
  methods,
  loadingSave,
  handleSave,
  handleEditEstimated,
  handleEditMortgage,
  currentEntry,
  setCurrentEntry,
  estimatedCalculatedValue,
  mortgageCalculatedValue
}: ProjectedPaymentsSceneProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const queryCache = useQueryCache();
  return (

    <form onSubmit={methods.handleSubmit((data) => handleSave(data))}>
      <Box className={classes.rootContainer}>
        <Paper>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Header
                t={t}
                isValid
                loading={loadingSave}
              />
            </Grid>

            <Grid item xs={12} className={classes.mainContainer}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.header}>
                    {t('projectedPayments:payments')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <PaymentSectionTable
                    methods={methods}
                    onEditEstimated={handleEditEstimated}
                    onEditMortgage={handleEditMortgage}
                    calculatedMortgageValue={mortgageCalculatedValue}
                    calculatedEstimatedEscrow={estimatedCalculatedValue}

                  />
                </Grid>

                <Grid item xs={6}>
                  <EstimatedTaxesSectionTable
                    methods={methods}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {currentEntry &&
          <AddLedgerEntry
            open={!!currentEntry}
            handleClose={() => { setCurrentEntry(undefined); }}
            onSuccesAddEntry={(data) => {
              const entries = data.filter(f => f.code.includes('estimated_escrow') || f.code.includes('mortgage_insurance'));
              const values = methods.getValues();
              queryCache.setQueryData(['order-projected-payemnts', orderId], {
                getOrder: {
                  projected_payments: values,
                  accounting: entries
                }
              });
            }}
            prevData={currentEntry as AccountingType}
            kindAllowedEntry={['manual']}
            codeAllowedEntry={['charge']}
            disableFields={{}}
            hideFields={{
              code: true,
              letter: true,
              kind: true,
              number: true,
              amount: true,
              annuality: true,
              entryDate: true,
              months: true,
              months_advanced: true,
            }}
            isEditingEntry
          />}
      </Box>

    </form>
  );
};

export default ProjectedPaymentsScene;
