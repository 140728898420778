import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLedgerItemColumn } from '../../services';

const useSettingTable = () => {
  const { t } = useTranslation();
  const [itemsCollapsed, setItems] = useState<{[key: string]: boolean}>({});
  const [filteredColumns, setFilteredColumns] = useState<TableLedgerItemColumn[]>([]);
  const setItemCollapse = (itemId: string) => {
    const items = { ...itemsCollapsed };
    items[itemId] = !items[itemId];
    setItems({ ...items });
  };

  return {
    itemsCollapsed,
    setItemCollapse,
    t,
    filteredColumns,
    setFilteredColumns
  };
};
export default useSettingTable;
