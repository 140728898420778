import { useDeletePayoff } from 'hooks/useDeletePayoff';
import { useQueryCache } from 'react-query';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useAlert from 'utils/alert';

import { useMortgagesContext } from '../../services/context';

const useMortgageDetails = () => {
  const { mortgageId } = useParams<{mortgageId:string}>();
  const { mortgages } = useMortgagesContext();
  const queryCache = useQueryCache();
  const { url } = useRouteMatch();
  const showAlert = useAlert();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [deleteMortgageMutation, deleteMortgageResponse] = useDeletePayoff({
    queryConfig: {
      onSuccess: () => {
        history.push(`${url.split('/').splice(-2).join('/')}`);
        showAlert('Success Deleted', 'success');
        queryCache.invalidateQueries(['order-mortgages', id],);
      },
      onError: () => {
        showAlert('there was an error', 'error');
      }
    }
  });

  return {
    currentMortgage: mortgages.find((f) => f._id === mortgageId),
    deleteMortgageMutation,
    deleteMortgageResponse,
    id
  };
};

export default useMortgageDetails;
