import gqlRequest from 'graphql/generic';
import { PartyAccounting } from 'types/ledger';
import { AccountingType } from 'v2-types/order';

type GetOrder = {
  getOrder: {
    accounting: AccountingType[];
    lenders_policy: {
      disclosure_section: string;
    };
    parties: PartyAccounting[];
  };
};

type filterchargesType = {
  accounting: string;
};

type GetOrderParams = {
  id: string;
  filters?: filterchargesType;
};

const query = `
  query GetOrder($id: ID!, $filters: OrderFilters) {
    getOrder(_id: $id, filters: $filters) {
		accounting {
			_id
			amount
			amount_calculated
			amount_override
      annuality
			code
			kind
			letter
			number
			entry_date
			description
			months
			per_month
			months_advanced
			involved_parties {
				_id
				amount
				at_closing_amount
				at_closing_percent
				before_closing_amount
				before_closing_percent
				kind
				name
				order_kinds
				payment_id
				payment_kind
				payment_reference
				percent
				accounted_amount
				accounted_percent
				accounted_by {
				  amount
				  percent
				  record {
					  _id
					description
					code
					entry_date
				  }
				}
			}   
		}
		lenders_policy {
      disclosure_section
    }
		parties {
      _id
    }
  }
}
`;

export const getOrder = (id: string, filters?: filterchargesType) =>
  gqlRequest<GetOrder, GetOrderParams>(query, { id, filters });
