import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { Estates, OrderProperty, PopertyMortages, } from 'types/order';
import { AccountingType } from 'v2-types/order';

import { NewAddressType } from '../../v2-types/user';
import { propertiesQuery } from './queries';

export type GQLCreateOrderProperty = {
  createOrderProperty: {
    accounting: AccountingType[]
  } & Estates & PopertyMortages
};

export const createOrderProperty = async (payload: { id: string, data: Partial<OrderProperty>, address: Partial<NewAddressType>}) => {
  const mutation = gql`
    mutation CreateOrderProperty($id: ID!, $data: OrderPropertyContent!, $address: AddressContent) {
      createOrderProperty(_id: $id, data: $data, address: $address) {
        ${propertiesQuery}
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLCreateOrderProperty>(mutation, payload);

  if (response.createOrderProperty.estates) {
    response.createOrderProperty.estates = response.createOrderProperty.estates?.map((estate) => ({
      ...estate,
      parcel_ids: estate.parcel_ids.map((parcelId) => ({ value: parcelId }))
    }));
  }

  return response;
};

export type GQLUpdateOrderProperty = {
  updateOrderProperty: {
    _id: string;
  };
};

export const updateOrderProperty = async (payload: {
  id: string,
  propertyId: string,
  data: Partial<OrderProperty>
}) => {
  const mutation = gql`
    mutation UpdateOrderProperty($id: ID!, $data: OrderPropertyContent!, $propertyId: ID!) {
      updateOrderProperty(_id: $id, data: $data, property_id: $propertyId) {
        _id
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLUpdateOrderProperty>(mutation, payload);

  return response;
};

export type GQLRemoveOrderProperty = {
  deleteOrderProperty: {
    _id: string;
  };
};

export const removeOrderProperty = async (payload: {
  id: string,
  propertyId: string
}) => {
  const mutation = gql`
    mutation DeleteOrderProperty($id: ID!, $propertyId: ID!) {
    deleteOrderProperty(_id: $id, property_id: $propertyId) {
      _id
    }
  }`;

  const response = await graphQLClient()
    .request<GQLRemoveOrderProperty>(mutation, payload);

  return response;
};
