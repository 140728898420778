import { useEffect } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';

import { getOrderMortgages } from './services/queries';
import useUpdateFromSocket from 'hooks/useUpdateFromSocket';

const useMortgagePayoffs = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  useUpdateFromSocket(['order-mortgages', orderId]);
  const getOrder = useQuery(
    ['order-mortgages', orderId],
    getOrderMortgages,
    {
		  enabled: !!orderId,
		  refetchOnWindowFocus: false,
		  refetchOnMount: false
    },
  );
  useEffect(() => {
    if (getOrder.isFetching) {
      showAlert('Getting new information', 'info');
    }
  }, [getOrder.isFetching, showAlert]);

  return {
    mortgages: getOrder.data?.getOrder.mortgages,
    loadingMortgages: getOrder.isLoading || getOrder.isFetching
  };
};

export default useMortgagePayoffs;
