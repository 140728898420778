import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ListingEntry } from 'graphql/listings/queries';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewTemplateType } from 'types/auto-templates';
import { listingItemtype } from 'types/ledger';

import { emptyTemplate } from '../utils/constants';
import { FormTemplate } from './form-template';

type ModalNewTemplateProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  createTemplate: (payload: NewTemplateType) => void;
  codeTemplates: string[];
  t: TFunction;
  partyRoles: ListingEntry[],
  transactionTypes: ListingEntry[],
  types: ListingEntry[],
  workflows: ListingEntry[]
};

export const ModalNewTemplate: FC<ModalNewTemplateProps> = ({
  open,
  setOpen,
  createTemplate,
  codeTemplates,
  t,
  partyRoles,
  transactionTypes,
  types,
  workflows
}) => (
  <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
    <DialogTitle>{t('documents:new-template')}</DialogTitle>
    <DialogContent>
      <FormTemplate
        handleOnSubmit={createTemplate}
        template={emptyTemplate}
        codeTemplates={codeTemplates}
        closeModal={() => setOpen(false)}
        partyRoles={partyRoles}
        transactionTypes={transactionTypes}
        types={types}
        workflows={workflows}
      />
    </DialogContent>
  </Dialog>
);

export default ModalNewTemplate;
