import { Grid } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AccountingType } from 'v2-types/order';

type filterFormTytpe = {
  selectedColumns: string,
  param: string,
  type: 'contains' | 'equal'
}

const filterOptions = [
  { text: 'code' },
  { text: 'description' },
  { text: 'amount' },
  { text: 'letter' },
  { text: 'number' },
];

const filterOptionsType = [
  { text: 'contains' },
  { text: 'equal' }
];

type filterComponentProps = {
  setFilter: (selectedColumns: string, param: string, type: string, clear: boolean) => void
}

const FilterComponent: FC<filterComponentProps> = ({ setFilter }) => {
  const {
    control,
    getValues,
    reset,
    watch
  } = useForm<filterFormTytpe>({
    defaultValues: {
      selectedColumns: '',
      param: '',
      type: 'contains'
    }
  });
  const watchedColumnsSelected = watch('selectedColumns');
  const watchedParam = watch('param');

  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="selectedColumns"
          render={({ field }) => (
            <SelectField
              {...field}
              inputRef={field.ref}
              emptyValue
              value={field.value}
              data={filterOptions}
              dataKey="text"
              dataValue="text"
              dataText="text"
              label="Select Column to filter"
              handleChange={field.onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <SelectField
              {...field}
              inputRef={field.ref}
              emptyValue
              value={field.value}
              data={filterOptionsType}
              dataKey="text"
              dataValue="text"
              dataText="text"
              label="Select Type of Filter"
              handleChange={field.onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="param"
          render={({ field }) => (
            <TextField
              {...field}
              label="Search"
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <BaseButton
              text="Apply filter"
              type="button"
              disabled={watchedColumnsSelected === '' || watchedParam === ''}
              onClick={(e) => {
                e.stopPropagation();
                const { param, type, selectedColumns } = getValues();
                setFilter(selectedColumns, param, type, false);
              }}
            />
          </Grid>
          <Grid item>
            <BaseButton
              text="Clear Results"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                const { param, type, selectedColumns } = getValues();
                reset({ param: '', selectedColumns: '' });
                setFilter(selectedColumns, param, type, true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterComponent;