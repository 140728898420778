import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  containerBtns: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

type HeaderProps = {
  title: string;
  handleAddEntry: () => void;
  handleReviseOrderLedger: () => void;
  loadingRevise: boolean;
};

const Header = ({ title, handleAddEntry, handleReviseOrderLedger, loadingRevise }: HeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid className={classes.containerBtns}>
        <Box className={classes.wrapper}>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            disabled={loadingRevise}
            onClick={handleReviseOrderLedger}
          >
            <Typography variant="body2" className={classes.text}>
              {t('accounting:request-revision')}
            </Typography>
          </Button>
          {loadingRevise && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Box>
        <Button color="secondary" variant="contained" disableElevation onClick={handleAddEntry}>
          <FontAwesomeIcon icon="plus" className={classes.icon} />
          <Typography variant="body2" className={classes.text}>
            {t('accounting:add-entry')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
