import { useSocket } from "contexts/socketcontext/hooks";
import { useEffect } from "react";

type Params = {
  eventKey: string,
  callback: (...args: any[]) => void
}

export const useSubscribeToSocketEvent = ({
  eventKey,
  callback
}: Params) => {
  const { socket } = useSocket();

  useEffect(() => {
    if (socket && socket.active) {
      socket.on(eventKey, callback);
    }
    return (() => {
      if (socket) {
        socket.removeAllListeners(eventKey);
      }
    });
  }, [socket]);
};