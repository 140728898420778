/*

*/
import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { OrderProperty } from 'types/order';

import { OrderMortgageType } from './types.d';

type returnOrderMortgages = {
	getOrder:{
		mortgages:OrderMortgageType[]
	}
}

export const getOrderMortgages = async (_: string, id: string) => {
  const query = gql`
	query Query($id: ID!) {
		getOrder(_id: $id) {
			accounting {
				_id
			}
			estates {
				address {
					address
					country
					street_address
				}
			}
			parties {
				_id
			}
		  mortgages {
				_id
				book
				certificate_title
				deed_date
				document_number
				instrument_number
				loan_number
				page
				recorded_date
				rescission_date
				estate {
					_id
					address {
					address
					}
					block
					jurisdiction
					legal_description
					lot
					parcel_ids
					range
					section
					subdivision
					survey_date
					survey_information
					survey_number
					township
					type
				}
				parties {
					_id
					name
					ssn
					kinds
				}
				accounting {
					description
					_id
					amount
					kind
					code
					entry_date
					amount_override
					involved_parties {
						_id
						amount
						accounted_amount
						accounted_percent
						accounted_by {
							amount
							percent
							record {
							_id
							code
							description
							}
						}
						at_closing_amount
						at_closing_percent
						before_closing_amount
						before_closing_percent
						kind
						name
						order_kinds
						payment_id
						percent
						}
					}
		  	}
			}
	  }
	  `;

  const response = await graphQLClient().request<returnOrderMortgages>(query, { id });

  return response;
};

export type getOnlyStates = {
	getOrder: {
		estates:Pick<OrderProperty, '_id' | 'address'>[]
	}
  };

export const getOrderProperties = async (
  payload: {
	  id: string,
	}
) => {
  const query = gql`
    query GetOrder($id: ID!, $filters: OrderFilters) {
      getOrder(_id: $id, filters: $filters) {
        estates {
          _id
          address {
            _id
            address
		  }
		}
	}
	}`;
  const response = await graphQLClient().request<getOnlyStates>(query, payload);
  return response;
};
