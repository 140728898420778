export const codeSelctor = [
  {
    key: 'calc',
    value: 'calc',
    text: 'Calc Spreadsheet',
    extension: '.ods',
  },
  {
    key: 'draw',
    value: 'draw',
    text: 'Draw Drawing',
    extension: '.odg',
  },
  {
    key: 'impress',
    value: 'impress',
    text: 'Impress Presentation',
    extension: '.odp',
  },
  {
    key: 'writer',
    value: 'writer',
    text: 'Writer Document',
    extension: '.odt',
  },
];

export const perElemts = [
  {
    code: 'party',
    description: 'Party',
  },
  {
    code: 'property',
    description: 'Property',
  },
  {
    code: 'signer',
    description: 'Signer',
  },
];

export const emptyTemplate = {
  _id: '',
  code: '',
  name: '',
  per: [],
  data: {
    property: 'All',
    signer: [],
    party: [],
    deliver: [],
  },
  triggers: {
    transaction_type: 'All',
    type: 'All',
    workflow: 'All',
  },
};
