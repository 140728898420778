import { Box, Button, Paper, Typography } from '@material-ui/core';
import { ListingEntry } from 'graphql/listings/queries';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { AutoTemplate, NewTemplateType } from 'types/auto-templates';
import { listingItemtype } from 'types/ledger';

import { FormTemplate } from './form-template';

type TemplateSelectedProps = {
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateTemplate: (data: NewTemplateType) => void;
  template: AutoTemplate;
  t: TFunction;
  partyRoles: ListingEntry[],
  transactionTypes: ListingEntry[],
  types: ListingEntry[],
  workflows: ListingEntry[]
};

export const TemplateSelected: FC<TemplateSelectedProps> = ({
  template,
  handleUpdateTemplate,
  setOpenEditModal,
  t,
  partyRoles,
  transactionTypes,
  types,
  workflows
}) => (
  <Paper style={{ padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" marginBottom={4}>
      <Typography variant="h6">
        {`${t('documents:template')} - ${template.name}`}
      </Typography>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenEditModal(true)}
      >
        <Typography variant="subtitle2">
          {t('documents:edit-document')}
        </Typography>
      </Button>
    </Box>
    <FormTemplate
      template={template}
      handleOnSubmit={handleUpdateTemplate}
      partyRoles={partyRoles}
      transactionTypes={transactionTypes}
      types={types}
      workflows={workflows}
    />
  </Paper>
);
