import gqlRequest from 'graphql/generic';

type ReviseOrderLedger = {
  reviseOrderLedger: {
    _id: string;
  };
};

type ReviseOrderLedgerParams = { id: string };

const query = `
  mutation Mutation($id: ID!) {
    reviseOrderLedger(_id: $id) {
      _id
    }
  }
`;

export const reviseOrderLedger = (id: string) =>
  gqlRequest<ReviseOrderLedger, ReviseOrderLedgerParams>(query, { id });

export default {};
