import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type OrderStatus = {
  name: string
}

export type GQLGetOrderStatusesResponse = {
  __type: {
    name: string,
    enumValues: OrderStatus[]
  }
}

export const getOrderStatuses = async () => {
  const query = gql`
    query {
      __type(name: "OrderStatuses"){
        name
        enumValues{
          name
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetOrderStatusesResponse>(query);
  return response;
};
