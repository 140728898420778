import { isValid } from 'date-fns';
import { iso31662 } from 'iso-3166';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useAlert from 'utils/alert';
import { HumanMessage } from "@langchain/core/messages";
import * as Yup from 'yup';

import {
  AGENCY_SELECTED_ID,
  CLOSING_DISCLOSURE,
  CURRENT_PATH,
  DEFAULT_ROUTE,
  PURCHASE,
  PURCHASE_STANDARD,
  REFINANCE,
  TOKEN_CLAIMS
} from 'utils/constants';
import { capitalize } from 'utils/helpers';
import { useGetListings } from 'hooks/useGetListings';
import { ChatOpenAI } from "@langchain/openai";
import {
  formInfoOrder,
  getPDFInfoPerPage,
  listValidKinds,
  parsedInfoPDF,
  processAmounts,
  processPDFRequest,
  resultPArseBlob,
  splitParties,
  validPropertyType
} from './services';
import { createOrderAI } from './services/mutations';
import { ListingEntry } from 'graphql/listings/queries';
import { yupResolver } from '@hookform/resolvers/yup';

const partySchema = Yup.object({
  deleted: Yup.boolean().notRequired(),
  partySelected: Yup.string().when('deleted', {
    is: (val) => val === true,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required()
  }),
});

const schema = Yup.object({
  purchase_price: Yup.number().moreThan(0),
  loan_amount: Yup.number().notRequired().nullable(),
  loan_number: Yup.string().when('loan_amount', {
    is: (val) => val > 0,
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),
  parties: Yup.array().of(partySchema)
});

const useStepperHooks = (pdfFImages: string[], onClose:()=>void) => {
  const agencySelected = localStorage.getItem(AGENCY_SELECTED_ID);
  const showAlert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const [createAIHelper, createOrderAIResponse] = useMutation(createOrderAI, {
    onSuccess: (data) => {
      onClose();
      localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: DEFAULT_ROUTE, param: '' }));
      history.push(`/orders/details/${data.helpOrderByAI._id}/${DEFAULT_ROUTE}`);
      showAlert(capitalize(t('dialogs:order-created')), 'success');
    },
    onError: () => {
      createOrderAIResponse.reset();
      showAlert(capitalize('There is an error'), 'error');
    }
  });
  const [shouldFetch, setShouldFetch] = useState(true);

  const methods = useForm<formInfoOrder>({
    defaultValues: {
      address: '',
      city: '',
      state: '',
      county: '',
      zip_code: '',
      country: '',
      purchase_price: 0,
      loan_amount: 0,
      order_type: '',
      property_type: '',
      earnest_money: 0,
      option_fee: 0,
      additional_earnest_money: 0,
      closing_date: '',
      selling_commission: 0,
	    listing_commission: 0,
      acceptance_date: '',
      workflow: 'Purchase Standard',
      transaction_type: 'Purchase',
      parties: [],
      loadingForm: false,
      loadingParties: false,
      errorForm: null,
      errorParties: null,
      loan_number: '',
      selling_commission_percent: 0,
      listing_commission_percent: 0,
      references: '',
      seller_company: false,
      buyer_company: false,
      other_broker_firm_company: false,
      listing_broker_firm_company: false,
      buyer_agent_company: false,
      seller_agent_company: false,
      jurisdiction: 'City'
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const states = iso31662.filter((state) => state.parent === 'US').map((state) => ({
    ...state,
    code: state.code.replace('US-', '')
  }));

  useEffect(() => {
    const getImagesResponse = async (pdf: resultPArseBlob[]) => {
      const { reset, setValue } = methods;
      setValue('loadingForm', true);
      const access_token = await localStorage.getItem(TOKEN_CLAIMS);
      const model = new ChatOpenAI({
        configuration: {
          baseURL: 'https://llm.titleproject.space',
        },
        modelName: 'claude-3-5',
        openAIApiKey: access_token!,
        temperature: 0.1,
        maxTokens: 4096
      });
      try {
        const responses = await Promise.all(pdf
          .filter(p => p?.page !== null && p?.question !== null)
          .map((p) => {
            return model.invoke(
              [new HumanMessage({ content: processPDFRequest(p) })]);
          }));

        const parsedResponse:parsedInfoPDF = responses.map((res) => {
          const { content } = res;
          try {
            const obj = JSON.parse((content as string).replace(/[\r\n]+/gm, ' '));
            return { ...obj };
		    } catch (_e:any) {
            return {};
		    }
        }).reduce((prev, curr) => ({ ...prev, ...curr }), {});
        const closingDate = new Date(parsedResponse.closing_date);
        const acceptanceDate = new Date(parsedResponse.acceptance_date);
        const selling_commission_percent = typeof parsedResponse?.selling_commision === 'string'
          ? parseInt(parsedResponse.selling_commision?.replace('%', '').replace('(', '').replace(')', ''), 10)
          : parsedResponse?.selling_commision;
        const purchase_price = parsedResponse?.purchase_price || 0;
        const selling_commission = (3 / 100) * purchase_price;
        reset({
          address: parsedResponse.address,
          city: parsedResponse.city,
          state: states.find((state) => state.code === parsedResponse.state) ? parsedResponse.state : '',
          county: parsedResponse.county,
          zip_code: parsedResponse.zip_code,
          country: parsedResponse.country,
          purchase_price: parsedResponse.purchase_price,
          loan_amount: parsedResponse.loan_amount,
          order_type: CLOSING_DISCLOSURE,
          property_type: validPropertyType.includes(parsedResponse.property_type) ? parsedResponse.property_type : '',
          earnest_money: parsedResponse.earnest_money,
          option_fee: parsedResponse.option_fee,
          additional_earnest_money: parsedResponse.additional_earnest_money,
          closing_date: isValid(closingDate) ? closingDate : null,
          acceptance_date: isValid(acceptanceDate) ? acceptanceDate : null,
          disbursemente_date: isValid(closingDate) ? closingDate : null,
          funding_date: isValid(closingDate) ? closingDate : null,
          selling_commission_percent,
          listing_commission_percent: selling_commission_percent,
          workflow: PURCHASE_STANDARD,
          selling_commission,
          listing_commission: selling_commission,
          transaction_type: PURCHASE,
          loadingForm: false,
          errorForm: null,
          loadingParties: true,
          references: parsedResponse.property_references,
          seller_company: parsedResponse.seller_company || false,
          buyer_company: parsedResponse.buyer_company || false,
          other_broker_firm_company: parsedResponse.other_broker_firm_company || false,
          listing_broker_firm_company: parsedResponse.listing_broker_firm_company || false,
          buyer_agent_company: parsedResponse.buyer_agent_company || false,
          seller_agent_company: parsedResponse.seller_agent_company || false,
          parties: splitParties(parsedResponse),
          jurisdiction: 'City'
        });
        setShouldFetch(false);
      } catch (err) {
        setValue('errorForm', 'there was an error, please try again');
        setShouldFetch(false);
      }
    };
    if (pdfFImages.length > 0 && shouldFetch) {
      getImagesResponse(getPDFInfoPerPage(pdfFImages, [1, 2, 3, 5, 9, 10]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfFImages, shouldFetch]);

  const [workflows, setWorkflows] = useState<ListingEntry[]>([{
    code: "Purchase Standard",
    description: "Purchase Standard"
  }]);

  const { data: transactionsResponse } = useGetListings({
    code: "allowed_transactions"
  });
  const transactionTypes = transactionsResponse?.getListing.entries ?? [];

  const { data: typesResponse } = useGetListings({
    code: "allowed_types"
  });

  const types = typesResponse?.getListing.entries.concat([{
    code: "Settlement Statement",
    description: "Settlement Statement"
  }]) ?? [];

  const selectTransactionType = (code: string) => {
    const workflowList: ListingEntry[] = [];
    if (code === PURCHASE) {
      workflowList.push({
        code: "Purchase Standard",
        description: "Purchase Standard"
      });
    }

    if (code === REFINANCE) {
      workflowList.push({
        code: "Refinance",
        description: "Refinance"
      });
    }

    methods.setValue('order_type', '');
    methods.setValue('workflow', '');
    setWorkflows(workflowList);
  };
  const handleCreateOrderAI = useCallback((data:formInfoOrder) => {
    const buyer = data.parties.find(f => f.role === 'buyer');
    createAIHelper({
      amounts: processAmounts(data, !!buyer),
      transactionType: data.transaction_type,
      type: data.order_type,
      workflow: data.workflow,
      loanNumber: data.loan_number,
      agencyId: agencySelected,
      involvedParties: data.parties.filter(p => p.deleted === false).map((party) => ({
        _id: party.partySelected,
        kinds: [listValidKinds[party.role].code]
      })),
	    property: {
        kind: data.property_type,
        country: data.country,
        state: data.state,
        postal_code: data.zip_code,
        street_address: data.address,
        settlement: data.county,
        locality: data.city,
        references: data.references
	    },
	    dates: {
        disbursement_date: data.disbursemente_date,
        acceptance_date: data.acceptance_date,
        closing_date: data.closing_date
	    },
    });
  }, [agencySelected, createAIHelper]);

  return {
    methods,
    states,
    handleCreateOrderAI,
    createOrderAIResponse,
    setShouldFetch,
    selectTransactionType,
    transactionTypes,
    workflows,
    types
  };
};

export default useStepperHooks;
