import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingType } from 'v2-types/order';

import {
  initialstateModals,
  reducerModals,
  sortedAccounting,
  TableLedgerItemColumn,
  validationSchema,
} from './services';
import { getOrder } from './services/querys';
import useMutationsData from './services/useMutationsdata';
import { reviseOrderLedger } from './services/mutations';
import useAlert from 'utils/alert';

const useAccounting = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [state, dispatch] = useReducer(reducerModals, initialstateModals);
  const resolver = useYupValidationResolver(validationSchema);
  const showAlert = useAlert();

  const getOrderAccounting = useQuery(['accounting-order', orderId], () => getOrder(orderId), {
    enabled: !!orderId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        setAccounting(sortedAccounting(data.getOrder.accounting || []));
      }
    },
  });

  const [reviseOrderLedgerRequest, reviseOrderLedgerResponse] = useMutation(reviseOrderLedger, {
    onSuccess: (data) => {
      if (data) {
        showAlert(t('accounting:success-revision'), 'success');
      }
    },
    onError: () => {
      showAlert(t('accounting:error-revision'), 'error');
    },
  });

  const { deleteAssociation, deleteEntry, deleteLink } = useMutationsData(setAccounting);

  const handleDeleteLedgerItem = (row: TableLedgerItemColumn) => {
    deleteEntry({
      id: orderId,
      accountingId: row.id,
    });
  };
  const handledeleteLink = (
    order_id: string,
    paymentRecordId: string,
    associationKind: string,
    associationId: string,
    associationLedgerRow: string
  ) => {
    deleteLink({
      orderId: order_id,
      targetEntryId: associationLedgerRow,
      accountingId: paymentRecordId,
      targetAssociationId: associationId,
      targetAssociationKind: associationKind,
    });
  };

  const handleDeleteLedgerParty = (item: any) => {
    const { row, ledgerInfo } = item;
    deleteAssociation({
      id: orderId,
      accountingId: ledgerInfo.id as string,
      kind: row.kind,
      partyId: row._id,
    });
  };

  const handleReviseOrderLedger = () => {
    reviseOrderLedgerRequest(orderId);
  };

  return {
    t,
    getOrderAccounting,
    accounting,
    setAccounting,
    handleDeleteLedgerItem,
    handleDeleteLedgerParty,
    resolver,
    handledeleteLink,
    state,
    dispatch,
    handleReviseOrderLedger,
    loadingRevise: reviseOrderLedgerResponse.isLoading,
  };
};

export default useAccounting;
