import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';

type infocomponentProps = {
  onClickEdit?: () => void,
  onClickDelete?: () => void,
  onClickPayer?: () => void,
}

const InfoComponent: FC<infocomponentProps> = ({
  onClickDelete,
  onClickEdit,
  onClickPayer
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <IconButton style={{ padding: 0 }} onClick={handleClick}>
        <FontAwesomeIcon icon="info-circle" size="sm" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          if (onClickEdit) {
            onClickEdit();
          }
          handleClose();
        }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>Edit Entry</Typography>
        </MenuItem>
        {onClickDelete &&
          <MenuItem onClick={(e) => {
            e.stopPropagation();
            onClickDelete();
            handleClose();
          }}
          >
            <Typography variant="body2" style={{ fontWeight: 500 }}>Delete Entry</Typography>
          </MenuItem>}
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          if (onClickPayer) {
            onClickPayer();
          }
          handleClose();
          window.scrollTo({ top: 5000, behavior: 'smooth' });
        }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>Edit Payor/Payee</Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default InfoComponent;
