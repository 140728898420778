import { Grid, makeStyles } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC, useEffect } from 'react';
import { capitalize } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import AddEntry from '../add-entry-modal/add-entry-modal';
import TotalDue from '../mortgage-detail/components/total-due';
import PaymentLineConfig from './components/payment-line-configuration';
import LineItem from './components/single-line-item';
import useMortgageItems from './line-items-hook';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: { color: theme.palette.tab.offselected },
  paddingContainer: { padding: theme.spacing(2) },
}));

type LineItemsProps = {
  accounting: AccountingType[];
  mortgateId: string;
};

const LineItems: FC<LineItemsProps> = ({ accounting, mortgateId }) => {
  const classes = useStyles();
  const { state, dispatch, deleteEntry, queryCache, id } = useMortgageItems(mortgateId);
  const { t } = useTranslation();

  useEffect(() => {
    if (state.mode === 'payments') {
      dispatch({
        type: 'payments',
        entryLedger: accounting.find((acc) => acc._id === state.entryLedger._id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounting]);
  return (
    <Grid container direction="column" className={classes.paddingContainer}>
      {accounting
        .filter((entry) => entry.code.includes('mortgage'))
        .map((entry) => (
          <LineItem
            key={entry._id}
            value={entry.amount}
            label={t(`payoffs:${entry.code.split('_')[1] || ''}`)}
            date={entry?.entry_date || ''}
            handlePayments={() => {
              dispatch({
                type: 'payments',
                entryLedger: entry,
              });
            }}
            handleEdit={() => {
              dispatch({
                type: 'edit',
                entryLedger: entry,
              });
            }}
          />
        ))}

      {accounting
        .filter((entry) => entry.code.includes('line_item'))
        .map((entry) => (
          <LineItem
            key={entry._id}
            value={entry.amount}
            label={t(`payoffs:${entry.description}`)}
            date={entry?.entry_date || ''}
            handlePayments={() => {
              dispatch({
                type: 'payments',
                entryLedger: entry,
              });
            }}
            handleEdit={() => {
              dispatch({
                type: 'edit',
                entryLedger: entry,
              });
            }}
            handleDelete={() => {
              deleteEntry({ id, accountingId: entry._id });
            }}
          />
        ))}
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <BaseButton
          text={t('payoffs:add-new-line-item')}
          icon="plus"
          onClick={() => {
            dispatch({ type: 'new' });
          }}
        />
      </Grid>
      {accounting.find((element) => element.code.includes('total_due')) && (
        <TotalDue totalDue={accounting.find((element) => element.code.includes('total_due'))!} />
      )}

      {state.mode === 'payments' && (
        <PaymentLineConfig
          currentEntry={state.entryLedger}
          handleClose={() => {
            dispatch({ type: null, entryLedger: null });
          }}
          handleSuccess={() => { }}
        />
      )}

      {(state.mode === 'edit' || state.mode === 'new') && (
        <AddEntry
          open={state.mode === 'edit' || state.mode === 'new'}
          handleClose={() => {
            dispatch({ type: null, entryLedger: null });
          }}
          onSuccesAddEntry={() => { }}
          prevData={state.entryLedger}
          kindAllowedEntry={['manual']}
          codeAllowedEntry={['line_item']}
          hideFields={{
            code: true,
            kind: true,
          }}
          mortgageId={mortgateId}
          additionalamount={
            accounting.find((acc) => acc.code.includes('principal'))?.amount_override || 0
          }
        />
      )}
    </Grid>
  );
};

export default LineItems;
