import { useSocket } from "contexts/socketcontext/hooks";
import { useEffect, useState } from "react";
import { QueryKey, useQueryCache } from "react-query";
import { useParams } from "react-router-dom";
import { infoUpdateType } from "types/notifications";

const useUpdateFromSocket = (queryKey: QueryKey) => {
  const { socket } = useSocket();
  const queryCache = useQueryCache();
  const { id: orderId } = useParams<{ id: string }>();
  const [time, setTime] = useState<string|null>(null);

  useEffect(() => {
    if (socket && socket.active) {
      socket.on('info-update', (data: infoUpdateType) => {
        const actualDate = new Date();
        if (
          data.name === 'Worker'
             && data.kind === 'workerAccountingLedger'
            && data.update?._id === orderId) {
          setTime(actualDate.getTime().toString());
          queryCache.invalidateQueries(queryKey);
        }
      });
    }
    return (() => {
      if (socket) {
        socket.removeAllListeners('info-update');
      }
    });
  }, [socket]);

  return {
    time
  };
};

export default useUpdateFromSocket;