import { NewUserType } from 'types/new-user';

const getquestionByPage = (page:number) => {
  switch (page) {
    case 1:
      return `
              You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
					   Give me a JSON without spaces with the next keys taking information  from the document:
						* address: address of the property
						* city: city of the property
						* state: state of the property in format ISO 3166-2
						* county: county of the property
						* zip_code: zip code of the property
						* country: US
						* property_references: put here the property references like the lot and additional information to found the property
						* seller_name: name from the seller of the property, if there are more that one, put them separated by commas
						* buyer_name: name from the buyer of the property, if there are more that one, put them separated by commas
						* purchase_price: the sales price of the property specified in the section 3C (Sales Price (Sum of A and B)) of the contract
						* loan_amount:  loan amount specified in the section 3B of the document
						* financing_addendum: put the option marked in the section 3B of the document as 'Sum of all financing described in the attached' 
						* cash_portion_paid_by_buyer: put the amount specified in the section 3A if there is nothing put 0
					    * property_type: based on the title which of these options is the property: 
							* Residence (1-4 Families),
							* Residence (5+ Families),
							* Commercial,
							* Condominium,
							* Planned Unit Development,
							* Land and Buildings,
							* Land Only,
							* Leasehold,
							* Commercial Leasehold,
							* Manufactured Home,
							* Mobile Home,
							* Wetlands 
		   `;
    case 2:
      return `
       You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
			Give me a JSON without spaces with the next keys taking information  from the document
				* earnest_money: put the amount earnest money defined in the document
				* option_fee: put the amount defined as option fee
				* additional_earnest_money: If there is an earnest money defined in the document, put it, if not, put a zero
				* escrow_agent: put the name of the escrow agent defined by the document
				* policy_payer:  put the name of who is marked at Seller shall furnish to Buyer 
			`;
    case 3:
      return `
       You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
			Give me a JSON without spaces with the next keys taking information  from the document
				* hoa: what is specified in the section E.2 of the document and place here the text up to the first point
		`;
    case 5:
      return `
       You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
				Give me a JSON without spaces with the next keys taking information  from the document:
					* closing_date: closing date of the document in format mm-dd-yyyy
			`;
    case 9:
      return `
       You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
				Give me a JSON without spaces with the next keys taking information  from the document:
					* seller: the name of the seller,if there are more that one, put them separated by commas
					* buyer: the name of the buyer,if there are more that one, put them separated by commas
          * seller_company: if the seller is a company put true,if not, put false
          * buyer_company:if the buyer is a company put true, if not, put false
					* acceptance_date: acceptance date of the document in format mm-dd-yyyy
			 `;

    case 10:
      return `
       You are an AI that only responds with JSON if there is no data to fill the JSON return a void JSON.
				Give me a JSON without spaces with the next keys taking information  from the document:
					* other_broker_firm: the name of the Other Broker firm in the document
					* listing_broker_firm: the name of the listing broker firm in the document
          * other_broker_firm_company: if the Other Broker is a company put true,if not, put false
          * listing_broker_firm_company: if the listing broker is a company put true, if not, put false
					* buyer_agent: Associate's Name of the Other broker firm in the left side of the document
					* seller_agent: Listing Associate's Name in the document
          * buyer_agent_company: if the Buyer agent is a company put true,if not, put false
          * seller_agent_company: if the Seller Agent is a company put true, if not, put false
					* selling_commision: the number of the percentaje that Listing Broker has agreed to pay Other Broker a fee
					* seller_agent: Listing Associate's Name in the document
				`;
    default:
      return null;
  }
};

export const getPDFInfoPerPage = (blobFiles: string[], numbers:number[]) => numbers.map((singlenumber) => ({
  page: blobFiles[singlenumber - 1],
  question: getquestionByPage(singlenumber)
}));

export type parsedInfoPDF ={
	  seller:string,
	  buyer: string,
    seller_company: boolean,
    buyer_company: boolean,
	  other_broker_firm: string,
	  listing_broker_firm: string,
    other_broker_firm_company:boolean,
    listing_broker_firm_company:boolean,
	  buyer_agent: string,
	  seller_agent:string,
    buyer_agent_company:boolean,
    seller_agent_company:boolean,
	  escrow_agent: string,
	  seller_name: string,
	  buyer_name: string,
	  address: string,
	  city: string,
	  state: string,
	  county: string,
	  zip_code: string,
	  country: string,
	  purchase_price: number,
	  loan_amount: number,
	  financing_addendum: string,
	  property_type: string,
	  earnest_money:number,
	  option_fee:number,
	  additional_earnest_money:number,
	  policy_payer: string,
	  closing_date: string,
	  selling_commision: string
	  hoa:string
	  acceptance_date: string
	  cash_portion_paid_by_buyer: number
	  property_references:string
  }

export type partyform ={
	role:string,
	text:string
	partySelected:string,
	parties:NewUserType[],
  deleted:boolean
}

export type formInfoOrder ={
	address: string,
	city: string,
	state: string,
	county: string,
	zip_code: string,
	country: string,
	purchase_price: number,
	loan_amount: number,
	order_type:string,
	property_type:string,
	earnest_money:number,
	option_fee:number,
	additional_earnest_money:number,
	closing_date: string | Date | null,
	acceptance_date: string | Date | null,
	funding_date: string | Date | null,
	disbursemente_date: string | null | Date,
	selling_commission: number,
	listing_commission: number,
  selling_commission_percent: number,
  listing_commission_percent: number,
	transaction_type: string,
	workflow:string,
	parties:partyform[],
	loadingParties:boolean,
	loadingForm:boolean,
	errorForm: string | null,
	errorParties:string | null,
	loan_number:string | null,
	references?:string,
  seller_company: boolean,
  buyer_company: boolean,
  other_broker_firm_company:boolean,
  listing_broker_firm_company:boolean,
  buyer_agent_company:boolean,
  seller_agent_company:boolean,
  jurisdiction:string
  }

export const splitParties = (param:parsedInfoPDF):partyform[] => {
  const hoaText = param.hoa.split('.')[0];
  const partiesKeys = ['seller', 'buyer', 'other_broker_firm', 'listing_broker_firm', 'buyer_agent', 'seller_agent'];
  const partiesRoles = partiesKeys.map((role) => ({
	  role,
	  text: param[role],
    deleted: false,
	  partySelected: ''
  })).reduce((prev, curr) => {
	  const namesbycommas = curr.text.split(',');
	  if (namesbycommas.length === 1) {
      return [
		  ...prev,
		  {
          ...curr,
          text: (curr.text === '' || curr.text === null) ? 'aaa' : curr.text,
		  }
      ];
	  }
	  return [
      ...prev,
      ...namesbycommas.map((item) => ({
		  role: curr.role,
		  text: (item === '' || item === null) ? 'aaa' : item,
		  partySelected: '',
        deleted: false,
		  parties: []
      }))
	  ];
  }, [] as any);

  if (hoaText.includes('is not')) {
    return partiesRoles;
  }
  return [
    ...partiesRoles,
    {
      role: 'hoa',
      text: 'hoa',
      deleted: false,
      partySelected: '',
	  parties: []
    }
  ];
};

export const validPropertyType = [
  'Residence (1-4 Families)',
  'Residence (5+ Families)',
  'Commercial',
  'Condominium',
  'Planned Unit Development',
  'Land and Buildings',
  'Land Only',
  'Leasehold',
  'Commercial Leasehold',
  'Manufactured Home',
  'Mobile Home',
  'Wetlands'
];

export const listValidKinds = {
  seller: {
    code: 'Seller',
    description: 'Seller'
  },
  buyer: {
    code: 'Buyer',
    description: 'Buyer'
  },
  other_broker_firm: {
    code: 'Real State Agent Buyer',
    description: 'Real State Agent Buyer'
  },
  listing_broker_firm: {
    code: 'Real State Agent Seller',
    description: 'Real State Agent Seller'
  },
  buyer_agent: {
    code: 'Agent Buyer',
    description: 'Agent Buyer'
  },
  seller_agent: {
    code: 'Agent Seller',
    description: 'Agent Seller'
  },
  hoa: {
    code: 'Homeowners Association',
    description: 'Homeowners Association'
  },
};

export type resultPArseBlob= {
	page: string;
  question: string | null;
}

export const processPDFRequest = (pdfpage:resultPArseBlob) => {
  return [{
    type: 'image_url',
    image_url: {
      url: pdfpage.page
    }
  },
  {
    type: "text",
    text: pdfpage.question
  }];
};

export const processAmounts = (formInformation:formInfoOrder, hasBuyer:boolean) => {
  const {
    loan_amount,
    purchase_price,
    earnest_money,
    option_fee,
    selling_commission,
    listing_commission
  } = formInformation;

  const loanAmountentry = { kind: 'Loan Amount', amount_override: Number(loan_amount) || 0, code: 'loan_amount' };
  const purchasePriceEntry = { kind: 'Purchase Price', amount_override: Number(purchase_price) || 0, code: 'purchase_price' };
  const earnestMoneyentry = { kind: 'Earnest', amount_override: Number(earnest_money) || 0, code: 'earnest' };
  const optionFeeentry = { kind: 'Option Fee', amount_override: Number(option_fee) || 0, code: 'option_fee' };
  const sellingCommisionentry = { kind: 'Selling Agent Comission', amount_override: Number(selling_commission) || 0, code: 'selling_agent_commission' };
  const listingCommisionentry = { kind: 'Listing agent commission', amount_override: Number(listing_commission) || 0, code: 'listing_agent_commission' };

  if (hasBuyer) {
    return [
      loanAmountentry,
      purchasePriceEntry,
      earnestMoneyentry,
      optionFeeentry,
      sellingCommisionentry,
      listingCommisionentry
    ];
  }

  return [
    loanAmountentry,
    purchasePriceEntry,

  ];
};
