import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, useFieldArray, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { formInfoOrder } from '../services';
import PartiesItemSearch from './parties-item-search';
import SectionTitle from './section-title';
import SkeletonParties from './skeleton-parties';
import { useTranslation } from 'react-i18next';

type partiesFormProps = {
  loadingParties: boolean,
  control: Control<formInfoOrder, object>,
  setValue: UseFormSetValue<formInfoOrder>,
  getValues: UseFormGetValues<formInfoOrder>
}

const PartiesForm: FC<partiesFormProps> = ({ control, loadingParties, setValue, getValues }) => {
  const { fields } = useFieldArray({
    control,
    name: 'parties',
  });

  const { t } = useTranslation();

  return (
    <>
      <SectionTitle title={t('orders:parties')} />
      <Grid item>
        <Grid container direction="column" spacing={3}>
          {loadingParties ? <SkeletonParties /> : fields.map((item, index) => (
            <Grid item key={item.id}>
              <PartiesItemSearch
                control={control}
                partyText={(item as any).text}
                role={(item as any).role}
                index={index}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PartiesForm;
