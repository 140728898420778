import { Box, makeStyles } from '@material-ui/core';
import { ChargesProvider } from 'contexts/chargesContext';
import React, { FC, ReactNode } from 'react';
import { general } from '../styles/general';

const useStyles = makeStyles((theme) => general(theme));

type Props = {
  children: ReactNode,
  chargesLetter: 'A' | 'B' | 'C' | 'E' | 'F' | 'G' | 'H'
}

const ChargesContainer: FC<Props> = ({ children, chargesLetter }) => {
  const classes = useStyles();
  return (
    <ChargesProvider chargesLetter={chargesLetter}>
      <Box className={classes.chargesContainer}>
        {children}
      </Box>
    </ChargesProvider>
  );
};

export default ChargesContainer;