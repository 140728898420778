import { parseISO } from 'date-fns';

import { REMOVE_LOCALSTORAGE } from './constants';

export const capitalize = (value: string, type = 'first'): string => {
  const sentence = value.toLowerCase();
  if (type === 'all') return sentence;
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const normalizeNumber = (value: string | number | undefined, type = 'phoneNumber'): string | number | undefined => {
  if (!value) return value;
  const number = value.toString();
  if (type === 'maskless') {
    return number.replace(/\D/g, '');
  }
  return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
};

export const generateRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatMoney = (number: number, decimals?: number) => number.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: decimals ?? 2 });

export const validateIsArray = (value: any) => Array.isArray(value);

export const toObject = (array: any[], key: string) => {
  const initialValue = {};
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue);
};

export const transformBytes = (bytes: number) => {
  const sizes = ['bytes', 'kb', 'mb', 'gb'];
  const base = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / (1024 ** base);
  return `${size.toFixed(2)} ${sizes[base]}`;
};

export const hasRole = (roles: string[] = [], role: string) => (
  roles.some((_role) => _role.includes(role))
);

export const fileExtension = (name: string, type: string) => {
  const getName = () => name.replace(/\.[^/.]+$/, '');

  const getExtension = () => name.match(/\.[^/.]+$/)![0].split('.')[1];

  switch (type) {
    case 'application/pdf':
      return { name: getName(), type: 'PDF', extension: '.pdf' };

    case 'image/png':
      return { name: getName(), type: 'PNG', extension: '.png' };

    case 'image/jpeg':
      return { name: getName(), type: 'JPG', extension: '.jpg' };

    default:
      return {
        name: getName(),
        type: getExtension().toUpperCase(),
        extension: `.${getExtension()}`,
      };
  }
};

export const formatCurrencyValueToNumber = (
  value: string | undefined,
  defaultReturn: string | number = 0,
  prefix: string | null = '$',
  separator: string | null = ','
) => {
  if (!value || value === prefix) {
    return defaultReturn;
  }

  if (prefix && separator) {
    return parseFloat(value?.split(prefix)[1].split(separator).join(''));
  }

  if (separator) {
    return parseFloat(value.split(separator).join(''));
  }

  return parseFloat(value);
};

export const formatStringInputToNumber = (value: string, returnZero: boolean = false) => {
  const parsed = parseFloat(value);

  if (!Number.isNaN(parsed)) {
    return parsed;
  }

  if (returnZero) {
    return 0;
  }

  return null;
};

export const truncateString = (value: string | null, end: number, start = 0) => {
  if (value === null) {
    return '';
  }

  return (
    value.length > end ? value.slice(start, end).concat('...') : value
  );
};

export const IntlCurrencyFormat = (value: number, style = 'currency') => {
  const noDecimals = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style,
    minimumFractionDigits: 0,
  }).format(value);

  const decimals = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style,
    minimumFractionDigits: 2,
  }).format(value);

  if (value % 1 === 0) {
    return noDecimals;
  }

  return decimals;
};

export const getNextIndex = (id: string, currentValues: any, values: any): any => {
  if (id !== currentValues) return { changeProperty: false };
  let nextIndex = values.map((item: any) => item.id).indexOf(id);
  nextIndex -= 1;
  if (nextIndex < 0) nextIndex = 1;
  return { shouldUpdate: true, id: values[nextIndex].id };
};

export const removeLSPersist = (id: string = '') => {
  const keys = Object.keys(localStorage).filter((e) => REMOVE_LOCALSTORAGE.some((key) => e.includes(`${key}${id}`)));
  keys.forEach((key) => localStorage.removeItem(key));
};

export const parseDate = (birth: string | null) => (birth ? parseISO(birth) : '');

export async function asyncForEach<T, Response>(array: T[], callback: (param: T, index?: number) => Response, delay?: number): Promise<Response[]> {
  const responseList: Response[] = [];
  for (let index = 0; index < array.length; index += 1) {
    const response = await callback(array[index], index);
    responseList.push(response);
    if (delay) await new Promise((resolve) => setTimeout(() => { resolve({}); }, delay));
  }

  return responseList;
}

export default {};
