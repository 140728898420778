import {
  createAutoTemplate,
  deleteAutoTemplate,
  updateAutoTemplate,
} from 'graphql/templates/mutations';
import {
  getDocumentTemplates,
  getTemplateTags,
} from 'graphql/templates/queries';
import { useGetListings } from 'hooks/useGetListings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AutoTemplate, NewTemplateType } from 'types/auto-templates';
import useAlert from 'utils/alert';
import { orderTemplatesAlphabetically } from './utils';

const useTemplates = () => {
  const showAlert = useAlert();
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [autoTemplates, setAutoTemplates] = useState<AutoTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<AutoTemplate>();
  const [codeTemplates, setCodeTemplates] = useState<string[]>([]);

  const updateDataTemplates = useCallback((templates: AutoTemplate[]) => {
    setAutoTemplates(orderTemplatesAlphabetically(templates));
  }, []);

  useQuery(
    ['autoTemplates'],
    getDocumentTemplates,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        updateDataTemplates(data.getAutoTemplates);
      },
    }
  );

  const { data: templateTagsResponse } = useQuery(['templateTags'], getTemplateTags, { refetchOnWindowFocus: false, });
  const { data: partyRolesResponse } = useGetListings({ code: "allowed_order_associations", queryConfig: { refetchOnWindowFocus: false } });
  const { data: transactionsResponse } = useGetListings({ code: "allowed_transactions", queryConfig: { refetchOnWindowFocus: false } });
  const { data: typesResponse } = useGetListings({ code: "allowed_types", queryConfig: { refetchOnWindowFocus: false } });
  const { data: workflowsRespone } = useGetListings({ code: "allowed_workflows", queryConfig: { refetchOnWindowFocus: false } });

  const partyRoles = partyRolesResponse?.getListing.entries ?? [];

  const transactionTypes = transactionsResponse?.getListing.entries ?
    [{
      code: "All",
      description: "All"
    }, ...transactionsResponse?.getListing.entries]
    : [];

  const types = typesResponse?.getListing.entries ?
    [{
      code: "All",
      description: "All"
    }, ...typesResponse?.getListing.entries]
    : [];

  const workflows = workflowsRespone?.getListing.entries ?
    [{
      code: "All",
      description: "All"
    }, ...workflowsRespone?.getListing.entries]
    : [];

  const [createTemplate] = useMutation(createAutoTemplate, {
    onSuccess: (data) => {
      updateDataTemplates([...autoTemplates, data.createAutoTemplate]);
      showAlert('Template was created', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const [deleteTemplate] = useMutation(deleteAutoTemplate, {
    onSuccess: (data) => {
      const deleteTemplateData = data.deleteAutoTemplate;
      if (deleteTemplateData._id === selectedTemplate?._id) {
        setSelectedTemplate(undefined);
      }
      updateDataTemplates(
        autoTemplates.filter(
          (template) => template._id !== deleteTemplateData._id
        )
      );
      showAlert('Template was deleted', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const [updateTemplate] = useMutation(updateAutoTemplate, {
    onSuccess: (data) => {
      const templateData = data.updateAutoTemplate;
      updateDataTemplates(
        autoTemplates.map((template) => {
          if (template._id === templateData._id) {
            return { ...templateData };
          }
          return template;
        })
      );
      showAlert('Template was updated', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const tagsField = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'text'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const tagsImages = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'image'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const tagsSignatures = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'signatures'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const handleDeleteTemplate = (id: string) => {
    deleteTemplate({ id });
  };

  const handleUpdateTemplate = (template: NewTemplateType) => {
    updateTemplate({
      id: template._id,
      ...template
    });
  };

  useEffect(() => {
    setCodeTemplates(autoTemplates.map((template) => template.code));
  }, [autoTemplates]);

  const handleCreateTemplate = (newTemplate: NewTemplateType) => {
    createTemplate(newTemplate);
  };

  return {
    autoTemplates,
    open,
    setOpen,
    handleCreateTemplate,
    handleDeleteTemplate,
    setSelectedTemplate,
    selectedTemplate,
    handleUpdateTemplate,
    setOpenEditModal,
    openEditModal,
    tagsField,
    tagsImages,
    tagsSignatures,
    codeTemplates,
    partyRoles,
    transactionTypes,
    types,
    workflows
  };
};

export default useTemplates;
