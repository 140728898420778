import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  makeStyles,
  Tabs,
  Typography
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { AlertAdvice } from 'components/common/alert-advice';
import { CustomTab as Tab } from 'components/common/custom-tab';
import { ContainedButton } from 'components/ContainedButton';
import { PropertiesMap } from 'components/PropertiesMap';
import React from 'react';
import {
  AMOUNTS_TAB,
  LEGAL_DESCRIPTION_TAB,
  PROPERTY_TAB,
  SUBDIVISIONS_TAB,
  SURVEY_TAB
} from 'utils/constants';

import { DeleteConfirmationModal } from '../../../components/delete-confirmation';
import SkeletonProperties from './components/skeleton-properties';
import { TabSurvey } from './components/survey/survey';
import { TabAmounts } from './components/tab-amounts/tab-amount';
import { TabLegalDescription } from './components/tab-legal-description/tab-legal-description';
import { TabProperty } from './components/tab-property/tab-property';
import { TabSubdivision } from './components/tab-subdivision/tab-subdivision';
import { useOrderFlowProperties } from './use-order-flow-properties';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  plusbutton: { marginLeft: theme.spacing(2) },
  textPlus: { color: theme.palette.primary.contrastText, fontWeight: 500 },
  panelcontainer: { padding: theme.spacing(1), },
}));

export const Properties = () => {
  const {
    t,
    theme,
    properties,
    isSaveLoading,
    onRemoveProperty,
    isRemovePropertyLoading,
    selectedProperty,
    selectProperty,
    currentTab,
    onSelectTab,
    onSaveProperty,
    onAddProperty,
    errors,
    dataIsValid,
    control,
    getValues,
    isCreatePropertyError,
    isUpdatePropertyError,
    setValue,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isPropertiesDataLoading,
    onConfirmRemovePropertyInformation,
    deleteErrorMessage,
    setDeleteErrorMessage,
    deletePropertyInformationLoading
  } = useOrderFlowProperties();
  const classes = useStyles(theme);

  return (
    <>
      <PropertiesMap
        properties={properties}
        onSelectProperty={selectProperty}
        selectedPropertyId={selectedProperty._id}
        mapHeight="100vh"
        header={(
          <Grid
            container
            justifyContent="space-between"
            className={classes.headerContainer}
          >
            <Typography
              variant="h6"
              className={classes.headerTitle}
            >
              {t('orders:properties')}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.plusbutton}
              onClick={() => {
                onAddProperty();
                selectProperty('');
              }}
              disabled={selectedProperty._id === ''}
            >
              <Typography
                variant="caption"
                className={classes.textPlus}
              >
                <FontAwesomeIcon size="lg" icon="plus" color={theme.palette.primary.main} />
              </Typography>
            </Button>
          </Grid>
        )}
      >
        {isPropertiesDataLoading ? <SkeletonProperties />
          : (
            <TabContext value={currentTab}>
              <Grid container direction="column">
                <Grid item container justifyContent="flex-end" spacing={2} style={{ padding: theme.spacing(0.5) }}>
                  <Grid item>
                    <ContainedButton
                      text={t('common:remove')}
                      icon="trash"
                      onClick={() => setIsDeleteModalOpen(true)}
                      disabled={!selectedProperty._id}
                    />
                  </Grid>
                  <Grid item>
                    <ContainedButton
                      text={t('common:save')}
                      icon="hdd"
                      onClick={onSaveProperty}
                      isLoading={isSaveLoading}
                      disabled={!dataIsValid}
                    />
                  </Grid>
                </Grid>
                {Object.keys(errors).length > 0 && (
                  <Grid item>
                    <AlertAdvice show message={t('orders:complete-all-fields')} />
                  </Grid>
                )}
                {(isCreatePropertyError || isUpdatePropertyError) && (
                  <Grid item>
                    <AlertAdvice show />
                  </Grid>
                )}
                <Grid item>
                  <Tabs
                    value={currentTab}
                    onChange={(e, value: string) => onSelectTab(value)}
                  >
                    <Tab
                      value={PROPERTY_TAB}
                      currentValue={currentTab}
                      text={t('orders:property')}
                    />
                    <Tab
                      value={LEGAL_DESCRIPTION_TAB}
                      currentValue={currentTab}
                      text={t('orders:legal-description')}
                    />
                    <Tab
                      value={SUBDIVISIONS_TAB}
                      currentValue={currentTab}
                      text={t('orders:subdivisions')}
                    />
                    <Tab
                      value={SURVEY_TAB}
                      currentValue={currentTab}
                      text={t('orders:survey')}
                    />
                    {selectedProperty.accounting.length > 0 && (
                      <Tab
                        value={AMOUNTS_TAB}
                        currentValue={currentTab}
                        text="Amounts"
                      />
                    )}
                  </Tabs>
                </Grid>
                <Grid item>
                  <TabPanel value={PROPERTY_TAB} className={classes.panelcontainer}>
                    <TabProperty
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </TabPanel>
                  <TabPanel value={LEGAL_DESCRIPTION_TAB} className={classes.panelcontainer}>
                    <TabLegalDescription
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel value={SUBDIVISIONS_TAB} className={classes.panelcontainer}>
                    <TabSubdivision
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel value={SURVEY_TAB} className={classes.panelcontainer}>
                    <TabSurvey
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel value={AMOUNTS_TAB} className={classes.panelcontainer}>
                    <TabAmounts
                      control={control}
                      propertyId={selectedProperty._id}
                      selectedProperty={selectedProperty}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            </TabContext>
          )}
      </PropertiesMap>
      <DeleteConfirmationModal
        title={t('properties:remove-property')}
        confirmationMessage={t('properties:remove-confirmation')}
        isOpen={isDeleteModalOpen}
        isRemoveLoading={isRemovePropertyLoading}
        onConfirm={onRemoveProperty}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <DeleteConfirmationModal
        title={t('properties:remove-property')}
        confirmationMessage={t('properties:hard-remove-confirmation')}
        isOpen={deleteErrorMessage !== ""}
        isRemoveLoading={deletePropertyInformationLoading}
        onConfirm={onConfirmRemovePropertyInformation}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteErrorMessage("");
        }}
      />
    </>
  );
};
