export type Data = { name: string };

export type TransactionType = {
  id: string;
  name: string;
  representing?: { name: string }[];
  types: { name: string }[];
  workflows: { name: string }[];
};

const TRANSACTION_TYPE = {
  purchase: 'Purchase',
  refinance: 'Refinance',
  heloc: 'HELOC',
  foreclosure: 'Foreclosure',
  bulkSale: 'Bulk sale',
  constructionBinder: 'Construction Binder',
  constructionLoan: 'Construction Loan',
  titleSearchOnly: 'Title Search Only',
  titleSearchOnlyRefinance: 'Title Search Only (Refinance)',
  commercialPurchase: 'Commercial Purchase',
  commercialRefinance: 'Commercial Refinance',
};

export const validateRepresenting: string[] = [
  TRANSACTION_TYPE.purchase,
  TRANSACTION_TYPE.foreclosure,
  TRANSACTION_TYPE.constructionLoan,
  TRANSACTION_TYPE.commercialPurchase,
];

export const validateAcceptanceDate : string[] = [
  TRANSACTION_TYPE.purchase,
  TRANSACTION_TYPE.foreclosure,
  TRANSACTION_TYPE.bulkSale,
  TRANSACTION_TYPE.constructionBinder,
  TRANSACTION_TYPE.constructionLoan,
  TRANSACTION_TYPE.titleSearchOnly,
  TRANSACTION_TYPE.titleSearchOnlyRefinance,
  TRANSACTION_TYPE.commercialPurchase,
];

export const validateRecissionDate : string [] = [
  TRANSACTION_TYPE.refinance,
  TRANSACTION_TYPE.heloc,
  TRANSACTION_TYPE.commercialRefinance
];

export const validateWorkflow: string[] = [
  TRANSACTION_TYPE.purchase,
  TRANSACTION_TYPE.refinance
];

/* new types */

export type DatesAndConfigurationForm ={
	acceptance_date: string | null;
	closing_date: string | null;
	contract_date: string | null;
	disbursement_date: string | null;
	funding_date: string | null;
	transaction_type: string;
	type: string;
	workflow: string;
	cash_only:boolean;
	construction_loan:boolean;
	heloc: boolean;
	order_id?:string | null;
	closing_place?: string | null;
  additional_fees_letter?:string | null,
  status: string
}

export type updateType = { time: string, type:'error' | 'success' | 'info' | 'warning' | undefined, message:string}
