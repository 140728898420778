import React from 'react';
import { Resolver } from 'react-hook-form';
import { DisabledFields } from 'types/disabledFields';
import { AccountingFilter, AccountingType } from 'v2-types/order';

import useAddRecipts from './add-entry-hooks';
import AddEntryScene from './add-entry-modal-scene';
import { EntryLedgerType } from './services/types';

type AddEntryProps = {
  open: boolean;
  handleClose: () => void;
  onSuccesAddEntry: (accounting: AccountingType[], editedEntry?: AccountingType | null) => void,
  prevData: AccountingType | null;
  kindAllowedEntry?: string | string[];
  codeAllowedEntry?: string | string[];
  resolver?: Resolver<EntryLedgerType, object> | undefined;
  disableFields?: DisabledFields<EntryLedgerType>;
  hideFields?: DisabledFields<EntryLedgerType>;
  isEditingEntry?: boolean;
  accountingFilter?: AccountingFilter;
  onAddEntry?: () => void;
};

const AddPartyEntry = ({
  handleClose,
  open,
  onSuccesAddEntry,
  prevData,
  kindAllowedEntry,
  codeAllowedEntry,
  resolver,
  disableFields,
  hideFields,
  isEditingEntry,
  accountingFilter,
  onAddEntry,
}: AddEntryProps) => {
  const {
    handleEntry,
    methods,
    allowedCodes,
    allowedKinds,
    allowedLetters,
    createErrors,
    t,
    loadingEditEntry,
    loadingAddNewEntry,
  } = useAddRecipts(
    handleClose,
    onSuccesAddEntry,
    prevData,
    kindAllowedEntry,
    codeAllowedEntry,
    resolver,
    isEditingEntry,
    accountingFilter,
    onAddEntry
  );
  const { setValue, getValues, control } = methods;

  const closeModal = () => {
    methods.reset();
    handleClose();
  };

  const handleResetValue = () => {
    const amount_calculated = getValues('amount_calculated');
    setValue('amount_override', amount_calculated);
  };

  return (
    <AddEntryScene
      open={open}
      onClose={closeModal}
      control={control}
      handleSubmit={handleEntry}
      allowedCodes={allowedCodes || []}
      allowedKinds={allowedKinds || []}
      allowedLetters={allowedLetters || []}
      errors={createErrors}
      prevData={prevData}
      t={t}
      loadingAddNewEntry={loadingAddNewEntry}
      loadingEditEntry={loadingEditEntry}
      handleResetValue={handleResetValue}
      methods={methods}
      disableFields={disableFields}
      hideFields={hideFields}
    />
  );
};

export default AddPartyEntry;
