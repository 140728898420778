import { TFunction } from 'i18next';
import {
  ROUTE_CHARGES,
  ROUTE_DATES_AND_CONFIG,
  ROUTE_DISBURSEMENTS_AND_RECEIPTS,
  ROUTE_DOCUMENTS,
  ROUTE_EARNEST_COMMISSIONS,
  ROUTE_LOANS,
  ROUTE_PARTIES,
  ROUTE_PAYMENTS,
  ROUTE_PAYOFFS,
  ROUTE_POLICY_INFO_AND_RATES,
  ROUTE_PROPERTIES,
  ROUTE_TAXES_AND_PRORATIONS,
} from 'utils/constants';

type GetMilestonesParams = {
  t: TFunction
}

export type MilestoneSection = {
  title: string,
  avatar: string,
  route: string,
  param?: string
}

export type Milestone = {
  title: string,
  date: string,
  key: 'general_info' | 'title' | 'disclosures'| 'charges' | 'payments' | 'closing_disclosure',
  icon: string,
  sections: MilestoneSection[]
}

export const getMilestones = ({
  t
}: GetMilestonesParams): Milestone[] => {
  return [
    {
      title: t('orders:general-info'),
      date: '',
      key: 'general_info',
      icon: 'briefcase',
      sections: [
        {
          title: t('orders:date-and-config'),
          avatar: 'DC',
          route: ROUTE_DATES_AND_CONFIG,
        },
        {
          title: t('orders:properties'),
          avatar: 'P',
          route: ROUTE_PROPERTIES,
        },
        {
          title: t('parties:parties'),
          avatar: 'PA',
          route: ROUTE_PARTIES,
        },
        {
          title: t('orders:loans'),
          avatar: 'L',
          route: ROUTE_LOANS,
        },
        {
          title: t('orders:earnest-&-commissions'),
          avatar: 'EC',
          route: ROUTE_EARNEST_COMMISSIONS,
        },
        {
          title: t('orders:taxes-&-prorations'),
          avatar: 'TP',
          route: ROUTE_TAXES_AND_PRORATIONS,
        },
        {
          title: t('orders:payoffs'),
          avatar: 'P',
          route: ROUTE_PAYOFFS,
        },
      ],
    },
    {
      title: t('orders:titles'),
      date: '',
      key: 'title',
      icon: 'bookmark',
      sections: [
        {
          title: t('policyInfoAndRates:policy-info-and-rates'),
          avatar: 'PR',
          route: ROUTE_POLICY_INFO_AND_RATES,
        },
        {
          title: t('orders:documents'),
          avatar: 'D',
          route: ROUTE_DOCUMENTS,
        },
      ],
    },
    {
      title: t('charges:charges'),
      date: '',
      key: 'charges',
      icon: 'file-invoice-dollar',
      sections: [
        {
          title: `${t('charges:charges')} A`,
          avatar: 'CA',
          route: ROUTE_CHARGES,
          param: 'a',
        },
        {
          title: `${t('charges:charges')} B`,
          avatar: 'CB',
          route: ROUTE_CHARGES,
          param: 'b',
        },
        {
          title: `${t('charges:charges')} C`,
          avatar: 'CC',
          route: ROUTE_CHARGES,
          param: 'c',
        },
        {
          title: `${t('charges:charges')} E`,
          avatar: 'CE',
          route: ROUTE_CHARGES,
          param: 'e',
        },
        {
          title: `${t('charges:charges')} F`,
          avatar: 'CF',
          route: ROUTE_CHARGES,
          param: 'f',
        },
        {
          title: `${t('charges:charges')} G`,
          avatar: 'CG',
          route: ROUTE_CHARGES,
          param: 'g',
        },
        {
          title: `${t('charges:charges')} H`,
          avatar: 'CG',
          route: ROUTE_CHARGES,
          param: 'h',
        },
        {
          title: `${t('charges:charges')} J`,
          avatar: 'CJ',
          route: ROUTE_CHARGES,
          param: 'j',
        },
        {
          title: `${t('charges:charges')} KM`,
          avatar: 'CK',
          route: ROUTE_CHARGES,
          param: 'k-m',
        },
        {
          title: `${t('charges:charges')} LN`,
          avatar: 'CL',
          route: ROUTE_CHARGES,
          param: 'l-n',
        },
      ],
    },
    {
      title: t('common:disclosures'),
      date: '',
      key: 'disclosures',
      icon: 'share-alt',
      sections: [
        {
          title: t('disclosure:projected-payments'),
          avatar: 'CC',
          route: 'projected-payments',
        },
        {
          title: t('disclosure:cash-to-close'),
          avatar: 'LD',
          route: 'cash-to-close',
        },
      ],
    },
    {
      title: t('payments:payments'),
      date: '',
      key: 'payments',
      icon: 'money-bill-wave-alt',
      sections: [
        {
          title: t('payments:wire-tranfers'),
          avatar: 'WT',
          route: ROUTE_PAYMENTS,
          param: 'wire-transfers',
        },
        {
          title: t('payments:disbursements-and-receipts'),
          avatar: 'DI',
          route: ROUTE_DISBURSEMENTS_AND_RECEIPTS,
        },
        {
          title: t('accounting:accounting'),
          avatar: 'AC',
          route: ROUTE_PAYMENTS,
          param: 'accounting',
        },
      ],
    },
    {
      title: t('closing:closing'),
      date: '',
      key: 'closing_disclosure',
      icon: 'file-alt',
      sections: [
        {
          title: t('closing:schedule-closing'),
          avatar: 'SC',
          route: 'closing',
          param: 'schedule-closing',
        },
      ],
    },
  ];
};

export default {};
