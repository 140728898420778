import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default LightTooltip;