import { Grid } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { AccountingType } from 'v2-types/order';
import { TableLedgerItemColumn } from '../../services';
import useAccountingContext from '../../services/useAccountingContext';
import { generateCollapseRow, ledgerColumns, parseLedgerColumns } from './services';
import useSettingTable from './table-ledger-hooks';
import VirtualizedTable from 'components/virtualized-table';
import FilterComponent from './components/filter-component';

type ledgerType = {
  ledgerData: AccountingType[],

}

const validateParam = (param: any) => {
  if (typeof param === 'number') {
    return param.toString();
  }
  return param as string;
};

const Ledger: FC<ledgerType> = ({ ledgerData, }) => {
  const { itemsCollapsed, setItemCollapse, t, setFilteredColumns, filteredColumns } = useSettingTable();
  const {
    handleAddParty,
    handleEditAmount,
    handleEditLedgerAssociation,
    handleDeleteLedgetEntry,
    handleDeleteLegerAssociation,
    handleCreateLink
  } = useAccountingContext()! || {};
  const columnscollapsed = generateCollapseRow(handleEditLedgerAssociation, handleDeleteLegerAssociation);
  const handleCollapseRow = useCallback((row: any) => {
    setItemCollapse(row.id);
  }, [setItemCollapse]);
  const columns = useMemo(() => ledgerColumns(t, handleEditAmount, handleCollapseRow, itemsCollapsed, handleAddParty, handleDeleteLedgetEntry, handleCreateLink),
    [handleAddParty, handleCollapseRow, handleDeleteLedgetEntry, handleEditAmount, itemsCollapsed, t, handleCreateLink]);

  const parsedcolumns = useMemo(() => parseLedgerColumns(ledgerData) as TableLedgerItemColumn[], [ledgerData]);

  const filterOptions = (selectedColumns: string, param: string, type: string, clear: boolean) => {
    setFilteredColumns(() => !clear ? parsedcolumns.filter(f => {
      const localParam = validateParam(f[selectedColumns]);
      if (type === 'contains') {
        if (localParam && localParam.toLowerCase().includes(param.toLowerCase())) {
          return f;
        }
        return undefined;
      }
      if (type === 'equal') {
        if (localParam && localParam.toLowerCase() === param.toLowerCase()) {
          return f;
        }
        return undefined;
      }
      return undefined;
    }) : []);
  };

  useEffect(() => {
    if (filteredColumns.length > 0) {
      setFilteredColumns(prev => prev.map(f => {
        const foundTableItem = parsedcolumns.find(g => g.id === f.id);
        if (foundTableItem) {
          return foundTableItem;
        }
        return f;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedcolumns]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <FilterComponent
          setFilter={filterOptions}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <VirtualizedTable
          columns={columns}
          dataSet={filteredColumns.length > 0 ? filteredColumns : parsedcolumns}
          itemsCollapsed={itemsCollapsed}
          columnCollapsing={columnscollapsed}
        />
      </Grid>
    </Grid>
  );
};

export default Ledger;
